<template>
  <div>
      <div class="card" v-if="loading">
          <div class="card-body">
               <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
          </div>
      </div>
      <div class="card" v-else-if="error">
          <div class="card-body">
               <div class="text-center text-danger my-2">
                    <span class="mb-2 d-block">Erro ao carregar dados!</span>
                    <b-button
                        variant="info"
                        @click.prevent="$router.push({ name: 'clientes' })"
                        >
                        <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
                        Listar Clientes
                    </b-button>
                </div>
          </div>
      </div>
      <CustomerEdit v-else :edit="customer"/>
  </div>
</template>

<script>
import CustomerEdit from './components/CustomerEdit'
import { mapActions } from 'vuex'
export default {
  components: {
    CustomerEdit,
  },
   created () {
      this.findCustomer()
  },
  data() {
    return {
        customer: null,
        loading: true,
        error: false
    }
  },
    methods: {
       ...mapActions('customer', ['ActionCustomerFind']),
       async findCustomer(){
           try {
                await this.ActionCustomerFind(this.$route.params.id).then(res=>{
                    this.customer = res.data.data
                    this.loading = false
                    this.error = false
                })
           } catch (e) {
               this.loading = false
               this.error = true
                this.customer = null
           }
       }
  },
}
</script>
